import { Box, Container, Typography } from "@mui/material";

export default function HowToUse() {
  const steps = [
    {
      title: "左側の絵文字を選ぶ",
      description: "左側のパネルから好きな絵文字を選択します。検索バーを使って絵文字を探すこともできます。",
    },
    {
      title: "右側の絵文字を選ぶ",
      description: "右側のパネルから組み合わせたい絵文字を選びます。選択可能な絵文字が表示されます。",
    },
    {
      title: "組み合わせを楽しむ",
      description: "中央に表示される新しい絵文字をダウンロードしたり、クリップボードにコピーしたりできます。",
    },
    {
      title: "ランダム機能を試す",
      description: "🎲ボタンをクリックすると、ランダムな組み合わせを試すことができます。思わぬ発見があるかもしれません。",
    },
  ];

  return (
    <Box 
      component="section" 
      sx={{ 
        py: { xs: 6, md: 8 },
        bgcolor: "background.paper",
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          sx={{ 
            fontSize: { xs: '1.75rem', md: '2.25rem' },
            mb: { xs: 4, md: 6 },
            textAlign: "center",
            fontWeight: 'bold',
            background: 'linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)',
            backgroundClip: 'text',
            color: 'transparent',
          }}
        >
          使い方
        </Typography>

        <Box 
          sx={{ 
            display: 'grid',
            gap: { xs: 4, md: 5 },
            maxWidth: '800px',
            mx: 'auto',
          }}
        >
          {steps.map((step, index) => (
            <Box 
              key={index}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: { xs: 2, sm: 4 },
              }}
            >
              <Typography 
                sx={{ 
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  fontWeight: 'bold',
                  color: 'primary.main',
                  opacity: 0.3,
                  flexShrink: 0,
                }}
              >
                {index + 1}
              </Typography>
              <Box>
                <Typography 
                  variant="h3" 
                  sx={{ 
                    fontSize: { xs: '1.25rem', md: '1.5rem' },
                    mb: 1,
                    fontWeight: 600,
                    color: 'text.primary',
                  }}
                >
                  {step.title}
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: 'text.secondary',
                    lineHeight: 1.7,
                    fontSize: { xs: '0.875rem', md: '1rem' },
                  }}
                >
                  {step.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
