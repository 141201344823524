import React, { useState, useEffect } from "react";
import {
  ImageListItem,
  Box,
  Container,
  Typography,
  IconButton,
  Menu,
  Fab,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { imageListItemClasses } from "@mui/material/ImageListItem";
import { Download, ContentCopy } from "@mui/icons-material";
import JSZip from "jszip";
import saveAs from "file-saver";
import { v4 as uuidv4 } from "uuid";
import { MouseCoordinates } from "./types";
import { getEmojiData, getSupportedEmoji } from "./utils";
import Search from "./search";
import RightEmojiList from "./right-emoji-list";
import LeftEmojiList from "./left-emoji-list";

export default function Kitchen() {
  // Selection helpers
  const [selectedLeftEmoji, setSelectedLeftEmoji] = useState("");
  const [selectedRightEmoji, setSelectedRightEmoji] = useState("");

  // Downloading helpers
  const [bulkDownloadMenu, setBulkDownloadMenu] = useState<
    MouseCoordinates | undefined
  >();
  const [isBulkDownloading, setIsBulkDownloading] = useState(false);

  // Search helpers
  const [leftSearchResults, setLeftSearchResults] = useState<Array<string>>([]);
  const [rightSearchResults, setRightSearchResults] = useState<Array<string>>(
    []
  );
  const [leftMobileSearchIsOpen, setLeftMobileSearchIsOpen] = useState(false);
  const [rightMobileSearchIsOpen, setRightMobileSearchIsOpen] = useState(false);

  // Hacks to get the search bar to update when I need it to
  const [leftUuid, setLeftUuid] = useState<string>(uuidv4());
  const [rightUuid, setRightUuid] = useState<string>(uuidv4());

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // 将条件渲染中的 state 提到顶层
  const [middleListItems, setMiddleListItems] = useState<React.ReactNode[]>([]);
  const [combinationItem, setCombinationItem] =
    useState<React.ReactNode | null>(null);

  // 使用 useEffect 来处理中间列表的更新
  useEffect(() => {
    async function loadMiddleList() {
      if (!selectedLeftEmoji) {
        setMiddleListItems([]);
        return;
      }

      if (selectedRightEmoji) {
        try {
          const data = await getEmojiData(selectedLeftEmoji);
          const combination = data.combinations[selectedRightEmoji].filter(
            (c) => c.isLatest
          )[0];

          if (!combination) {
            setError("No combination found for selected emojis");
            return;
          }

          setCombinationItem(
            <ImageListItem>
              <img
                alt={combination.alt}
                src={combination.gStaticUrl}
                style={{
                  width: "128px",
                  height: "128px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </ImageListItem>
          );
        } catch (err) {
          console.error("Error loading combination:", err);
          setError(
            err instanceof Error ? err.message : "Failed to load combination"
          );
        }
      } else {
        try {
          const data = await getEmojiData(selectedLeftEmoji);
          const items = Object.entries(data.combinations).flatMap(
            ([rightEmoji, combinations]) =>
              combinations
                .filter((c) => c.isLatest)
                .map((combination) => (
                  <ImageListItem key={combination.alt}>
                    <img
                      loading="lazy"
                      alt={combination.alt}
                      src={combination.gStaticUrl}
                      style={{
                        width: "128px",
                        height: "128px",
                        margin: "auto",
                        display: "block",
                      }}
                    />
                  </ImageListItem>
                ))
          );
          setMiddleListItems(items);
        } catch (err) {
          console.error("Error loading combinations:", err);
          setError(
            err instanceof Error ? err.message : "Failed to load combinations"
          );
        }
      }
    }

    loadMiddleList();
  }, [selectedLeftEmoji, selectedRightEmoji]);

  // 确定要显示的内容
  let middleList;
  const showOneCombo = selectedLeftEmoji !== "" && selectedRightEmoji !== "";

  if (selectedLeftEmoji === "" && selectedRightEmoji === "") {
    middleList = <div></div>;
  } else if (selectedLeftEmoji !== "" && selectedRightEmoji === "") {
    middleList = middleListItems;
  } else {
    middleList = combinationItem;
  }

  /**
   * 👈 Handler when an emoji is selected from the left-hand list
   */
  const handleLeftEmojiClicked = async (emojiCodepoint: string) => {
    setSelectedLeftEmoji(emojiCodepoint);
    setSelectedRightEmoji(""); // 清除右侧选择
    setLeftUuid(uuidv4()); // 更新搜索栏

    // 重置右侧搜索结果
    setRightSearchResults([]);
    setRightUuid(uuidv4());
  };

  /**
   * 🎲 Handle left-hand randomize button clicked
   */
  const handleLeftEmojiRandomize = async () => {
    try {
      setError(null);
      setIsLoading(true);

      // 获取所有支持的表情
      const supportedEmoji = await getSupportedEmoji();
      if (supportedEmoji.length === 0) {
        throw new Error("No supported emoji found");
      }

      // 随机选择一个表情
      const randomIndex = Math.floor(Math.random() * supportedEmoji.length);
      const randomEmoji = supportedEmoji[randomIndex];

      // 清除右侧选择
      setSelectedRightEmoji("");
      setRightSearchResults([]);

      // 设置左侧表情
      setSelectedLeftEmoji(randomEmoji);
      setLeftSearchResults([]);

      // 更新 UUID 以触发搜索框重置
      setLeftUuid(uuidv4());
      setRightUuid(uuidv4());
    } catch (err) {
      console.error("Error in left emoji randomize:", err);
      setError(
        err instanceof Error ? err.message : "Failed to randomize left emoji"
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 👉 Handler when an emoji is selected from the right-hand list
   */
  const handleRightEmojiClicked = (emojiCodepoint: string) => {
    if (!selectedLeftEmoji) return;
    setSelectedRightEmoji(emojiCodepoint);
    setRightUuid(uuidv4());
  };

  /**
   * 🎲 Handle right-hand randomize button clicked
   */
  const handleRightEmojiRandomize = async () => {
    if (!selectedLeftEmoji) {
      return;
    }

    try {
      setError(null);
      setIsLoading(true);

      // 获取左侧表情的数据
      const data = await getEmojiData(selectedLeftEmoji);
      const validCombinations = Object.keys(data.combinations);

      if (validCombinations.length === 0) {
        throw new Error("No valid combinations found");
      }

      // 过滤掉当前选中的左侧表情
      const availableCombinations = validCombinations.filter(
        (emoji) => emoji !== selectedLeftEmoji
      );

      if (availableCombinations.length === 0) {
        throw new Error("No valid combinations available");
      }

      // 随机选择一个组合
      const randomIndex = Math.floor(
        Math.random() * availableCombinations.length
      );
      const randomEmoji = availableCombinations[randomIndex];

      // 设置右侧表情
      setSelectedRightEmoji(randomEmoji);
      setRightSearchResults([]);

      // 更新 UUID 以触发搜索框重置
      setRightUuid(uuidv4());
    } catch (err) {
      console.error("Error in right emoji randomize:", err);
      setError(
        err instanceof Error ? err.message : "Failed to randomize right emoji"
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 🎲 Handle full randomize button clicked
   */
  const handleFullEmojiRandomize = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // 获取所有支持的表情
      const knownSupportedEmoji = await getSupportedEmoji();
      if (knownSupportedEmoji.length === 0) {
        throw new Error("No supported emoji found");
      }

      // 随机选择左侧表情
      const randomLeftIndex = Math.floor(
        Math.random() * knownSupportedEmoji.length
      );
      const randomLeftEmoji = knownSupportedEmoji[randomLeftIndex];

      // 获取左侧表情的组合数据
      const data = await getEmojiData(randomLeftEmoji);
      const validCombinations = Object.keys(data.combinations).filter(
        (emoji) => emoji !== randomLeftEmoji
      );

      if (validCombinations.length === 0) {
        throw new Error("No valid combinations found for selected emoji");
      }

      // 随机选择右侧表情
      const randomRightIndex = Math.floor(
        Math.random() * validCombinations.length
      );
      const randomRightEmoji = validCombinations[randomRightIndex];

      // 清除搜索结果
      setLeftSearchResults([]);
      setRightSearchResults([]);

      // 按顺序设置状态
      setSelectedLeftEmoji(randomLeftEmoji);
      setLeftUuid(uuidv4());

      // 确保状态更新完成后再设置右侧表情
      await new Promise((resolve) => setTimeout(resolve, 100));

      setSelectedRightEmoji(randomRightEmoji);
      setRightUuid(uuidv4());
    } catch (err) {
      console.error("Error in full randomize:", err);
      setError(
        err instanceof Error ? err.message : "Failed to randomize emojis"
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 💭 Helper function to open the bulk download menu
   */
  const handleBulkImageDownloadMenuOpen = (event: React.MouseEvent) => {
    event.preventDefault();
    setBulkDownloadMenu(
      bulkDownloadMenu === undefined
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : undefined
    );
  };

  /**
   * 💾 Handle bulk combination downloads
   */
  const handleBulkImageDownload = async () => {
    try {
      const currentDate = new Date();
      const dateWithOffset = new Date(
        currentDate.getTime() - currentDate.getTimezoneOffset() * 60000
      );
      (JSZip as any).defaults.date = dateWithOffset;

      const zip = new JSZip();
      const data = await getEmojiData(selectedLeftEmoji);
      const photoZip = zip.folder(data.alt)!;

      setIsBulkDownloading(true);

      const combinations = Object.values(data.combinations)
        .flat()
        .filter((c) => c.isLatest);

      for (const combination of combinations) {
        const image = await fetch(combination.gStaticUrl);
        const imageBlob = await image.blob();
        photoZip.file(`${combination.alt}.png`, imageBlob);
      }

      const archive = await zip.generateAsync({ type: "blob" });
      saveAs(archive, data.alt);

      setBulkDownloadMenu(undefined);
      setIsBulkDownloading(false);
    } catch (err) {
      console.error("Error in bulk download:", err);
      setError(
        err instanceof Error ? err.message : "Failed to download images"
      );
      setBulkDownloadMenu(undefined);
      setIsBulkDownloading(false);
    }
  };

  /**
   * 💾 Handle single combination downloads
   */
  const handleImageDownload = async () => {
    try {
      const data = await getEmojiData(selectedLeftEmoji);
      const combination = data.combinations[selectedRightEmoji].filter(
        (c) => c.isLatest
      )[0];

      saveAs(combination.gStaticUrl, combination.alt);
    } catch (err) {
      console.error("Error in image download:", err);
      setError(err instanceof Error ? err.message : "Failed to download image");
    }
  };

  /**
   * 💾 Handle single image copy-to-clipboard
   */
  const handleImageCopy = async () => {
    try {
      const data = await getEmojiData(selectedLeftEmoji);
      const combination = data.combinations[selectedRightEmoji].filter(
        (c) => c.isLatest
      )[0];

      // 获取图片数据
      const response = await fetch(combination.gStaticUrl);
      const blob = await response.blob();

      // 检查是否支持新的 Clipboard API
      if (navigator.clipboard && navigator.clipboard.write) {
        try {
          await navigator.clipboard.write([
            new ClipboardItem({
              "image/png": blob,
            }),
          ]);
        } catch (err) {
          // 如果 Clipboard API 失败，使用替代方案
          fallbackCopy(blob);
        }
      } else {
        // 对于不支持 Clipboard API 的设备使用替代方案
        fallbackCopy(blob);
      }
    } catch (err) {
      console.error("Error in image copy:", err);
      setError(err instanceof Error ? err.message : "Failed to copy image");
    }
  };

  // 添加替代复制方案
  const fallbackCopy = async (blob: Blob) => {
    try {
      // 创建临时链接并触发下载
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "emoji-mix.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      // 显示提示信息
      alert("画像を保存しました！");
    } catch (err) {
      console.error("Error in fallback copy:", err);
      setError("画像のコピーに失敗しました。");
    }
  };

  // See: https://caniuse.com/async-clipboard
  var hasClipboardSupport = "write" in navigator.clipboard;

  if (error) {
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Typography color="error">エラーが発生しました: {error}</Typography>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Typography>読み込み中...</Typography>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        flexGrow: "1",
        display: "flex",
        flexDirection: "row",
        mt: 1,
        position: "relative",
        height: "calc(100vh - 300px)", // 减去Header和FAQ的高度
        minHeight: "500px", // 设置最小高度
      }}
    >
      {/* Left Emoji Column */}
      <Box
        sx={{
          overflowY: "auto",
          justifyItems: "center",
          width: "33%",
          height: "100%", // 确保高度填满容器
          borderRight: 1,
          borderColor: "divider",
        }}
      >
        {/* Left Search */}
        <Search
          setSearchResults={setLeftSearchResults}
          setMobileSearchIsOpen={setLeftMobileSearchIsOpen}
          handleRandomize={handleLeftEmojiRandomize}
          selectedEmoji={selectedLeftEmoji}
          uuid={leftUuid}
          isRightSearch={false}
        />

        {/* Left Emoji List */}
        <Box
          sx={{
            marginTop: leftMobileSearchIsOpen ? "64px" : 0,
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(3, 1fr)",
              sm: "repeat(5, 1fr)",
              md: "repeat(7, 1fr)",
              lg: "repeat(9, 1fr)",
              xl: "repeat(10, 1fr)",
            },
            [`& .${imageListItemClasses.root}`]: {
              display: "flex",
            },
          }}
        >
          <LeftEmojiList
            leftSearchResults={leftSearchResults}
            selectedLeftEmoji={selectedLeftEmoji}
            handleLeftEmojiClicked={handleLeftEmojiClicked}
            handleBulkImageDownloadMenuOpen={handleBulkImageDownloadMenuOpen}
          />
        </Box>

        {/* Bulk Download Menu */}
        {selectedLeftEmoji !== "" ? (
          <Menu
            open={bulkDownloadMenu !== undefined}
            onClose={() => setBulkDownloadMenu(undefined)}
            anchorReference="anchorPosition"
            anchorPosition={
              bulkDownloadMenu !== undefined
                ? {
                    top: bulkDownloadMenu.mouseY,
                    left: bulkDownloadMenu.mouseX,
                  }
                : undefined
            }
          >
            <LoadingButton
              sx={{ mx: 1 }}
              loading={isBulkDownloading}
              loadingPosition="start"
              startIcon={<Download fontSize="small" />}
              onClick={handleBulkImageDownload}
            >
              一括ダウンロード {/* Changed from "Bulk Download" */}
            </LoadingButton>
          </Menu>
        ) : undefined}
      </Box>

      {/* Middle Emoji Column */}
      <Box
        sx={{
          mx: 3,
          overflowY: "auto",
          width: "33%",
          height: "100%", // 确保高度填满容器
          display: showOneCombo ? "flex" : null,
          alignItems: showOneCombo ? "center" : null,
          justifyContent: showOneCombo ? "center" : null,
        }}
      >
        {/* Random Button */}
        <Fab
          color="primary"
          onClick={handleFullEmojiRandomize}
          sx={{
            position: "absolute",
            bottom: 20,
            right: "35%",
            zIndex: 1,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Noto Emoji, Apple Color Emoji, sans-serif",
              height: "24px",
            }}
          >
            🎲
          </Typography>
        </Fab>

        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: showOneCombo ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
              md: showOneCombo ? "repeat(1, 1fr)" : "repeat(3, 1fr)",
            },
            [`& .${imageListItemClasses.root}`]: {
              display: "flex",
            },
          }}
        >
          {middleList}
          {showOneCombo ? (
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                pt: 2,
              }}
            >
              <IconButton onClick={handleImageCopy} title="保存/コピー">
                <ContentCopy />
              </IconButton>
              <IconButton onClick={handleImageDownload} title="ダウンロード">
                <Download />
              </IconButton>
            </Container>
          ) : null}
        </Box>
      </Box>

      {/* Right Emoji Column */}
      <Box
        sx={{
          overflowY: "auto",
          justifyItems: "center",
          width: "33%",
          height: "100%", // 确保高度填满容器
          borderLeft: 1,
          borderColor: "divider",
        }}
      >
        {/* Right Search */}
        <Search
          setSearchResults={setRightSearchResults}
          setMobileSearchIsOpen={setRightMobileSearchIsOpen}
          handleRandomize={handleRightEmojiRandomize}
          selectedEmoji={selectedRightEmoji}
          uuid={rightUuid}
          isRightSearch={true}
          disabled={selectedLeftEmoji === ""}
        />

        {/* Right Emoji List */}
        <Box
          sx={{
            marginTop: rightMobileSearchIsOpen ? "64px" : 0,
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(3, 1fr)",
              sm: "repeat(5, 1fr)",
              md: "repeat(7, 1fr)",
              lg: "repeat(9, 1fr)",
              xl: "repeat(10, 1fr)",
            },
            [`& .${imageListItemClasses.root}`]: {
              display: "flex",
            },
          }}
        >
          <RightEmojiList
            rightSearchResults={rightSearchResults}
            selectedLeftEmoji={selectedLeftEmoji}
            selectedRightEmoji={selectedRightEmoji}
            handleRightEmojiClicked={handleRightEmojiClicked}
          />
        </Box>
      </Box>
    </Container>
  );
}
