import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { Helmet } from "react-helmet";

// 预设的表情数据
const EMOJI_LIST = [
  {
    category: "笑顔・ポジティブ",
    emojis: [
      {
        emoji: "😀",
        name: "にっこり笑顔",
        description: "明るく笑っている顔。友好的で前向きな感情を表現します。",
        usage: "挨拶や喜びの気持ちを伝える時に使用します。",
      },
      {
        emoji: "😃",
        name: "大きい目の笑顔",
        description: "目を大きく開いて笑っている顔。より強い喜びを表現します。",
        usage: "大きな喜びや興奮を伝える時に使用します。",
      },
      {
        emoji: "😄",
        name: "目もにこやかな笑顔",
        description: "目も笑っている明るい表情。純粋な喜びを表現します。",
        usage: "心からの喜びを伝える時に使用します。",
      },
      {
        emoji: "😁",
        name: "輝く笑顔",
        description:
          "歯を見せて大きく笑っている顔。誇らしい気持ちを表現します。",
        usage: "達成感や自信を伝える時に使用します。",
      },
      {
        emoji: "😆",
        name: "目を閉じて笑う顔",
        description: "目を閉じて大笑いしている顔。面白さや楽しさを表現します。",
        usage: "とても面白いことがあった時に使用します。",
      },
    ],
  },
  {
    category: "愛情・感情",
    emojis: [
      {
        emoji: "🥰",
        name: "ハートの目の笑顔",
        description: "目がハートの笑顔。愛情や幸せな気持ちを表現します。",
        usage: "愛情や感謝の気持ちを伝える時に使用します。",
      },
      {
        emoji: "😍",
        name: "目がハートの顔",
        description: "目がハートになった顔。強い愛情や憧れを表現します。",
        usage: "大好きな人やものについて話す時に使用します。",
      },
      {
        emoji: "🥲",
        name: "嬉し涙の笑顔",
        description: "笑顔で涙を流している顔。感動や喜びを表現します。",
        usage: "感動的な場面や嬉しい出来事があった時に使用します。",
      },
      {
        emoji: "😊",
        name: "優しい笑顔",
        description: "目を細めて微笑んでいる顔。穏やかな幸せを表現します。",
        usage: "優しい気持ちや感謝を伝える時に使用します。",
      },
      {
        emoji: "🤗",
        name: "ハグの顔",
        description: "抱きしめようとしている表情。温かい気持ちを表現します。",
        usage: "感謝や励ましを伝える時に使用します。",
      },
    ],
  },
  {
    category: "遊び・楽しみ",
    emojis: [
      {
        emoji: "😋",
        name: "美味しい顔",
        description: "舌を出して喜んでいる顔。美味しさや満足を表現します。",
        usage: "美味しい食事の話題で使用します。",
      },
      {
        emoji: "🤪",
        name: "変な顔",
        description: "目がバラバラの変な顔。面白おかしさを表現します。",
        usage: "ふざけた雰囲気を出したい時に使用します。",
      },
      {
        emoji: "🤣",
        name: "転げ笑い",
        description: "笑いすぎて転げ回っている顔。最高の面白さを表現します。",
        usage: "とても面白いことがあった時に使用します。",
      },
      {
        emoji: "😝",
        name: "舌を出してウインク",
        description: "舌を出してウインクしている顔。茶目っ気を表現します。",
        usage: "冗談を言う時や遊び心を出したい時に使用します。",
      },
    ],
  },
  {
    category: "困惑・心配",
    emojis: [
      {
        emoji: "😅",
        name: "冷や汗の笑顔",
        description: "冷や汗をかきながら笑っている顔。困惑を表現します。",
        usage: "気まずい状況や失敗した時に使用します。",
      },
      {
        emoji: "😓",
        name: "冷や汗",
        description: "冷や汗をかいている顔。困惑や焦りを表現します。",
        usage: "困った状況や焦っている時に使用します。",
      },
      {
        emoji: "😟",
        name: "心配顔",
        description: "眉をひそめて心配そうな顔。不安な気持ちを表現します。",
        usage: "心配事がある時や不安な時に使用します。",
      },
      {
        emoji: "😕",
        name: "困惑顔",
        description: "困惑している表情。戸惑いや混乱を表現します。",
        usage: "状況が理解できない時や迷っている時に使用します。",
      },
    ],
  },
  {
    category: "眠い・疲れ",
    emojis: [
      {
        emoji: "😪",
        name: "眠い顔",
        description: "眠くて涙を流している顔。眠気を表現します。",
        usage: "眠い時や疲れている時に使用します。",
      },
      {
        emoji: "😴",
        name: "寝ている顔",
        description: "「zzz」と共に寝ている顔。睡眠を表現します。",
        usage: "寝る時や休憩する時に使用します。",
      },
      {
        emoji: "🥱",
        name: "あくび顔",
        description: "あくびをしている顔。眠気や退屈を表現します。",
        usage: "眠い時や退屈な時に使用します。",
      },
    ],
  },
  {
    category: "病気・体調不良",
    emojis: [
      {
        emoji: "🤒",
        name: "熱のある顔",
        description: "体温計を含む病気の顔。発熱を表現します。",
        usage: "体調が悪い時や病気の時に使用します。",
      },
      {
        emoji: "🤢",
        name: "吐き気顔",
        description: "吐き気を感じている緑色の顔。気分の悪さを表現します。",
        usage: "気分が悪い時や体調不良の時に使用します。",
      },
      {
        emoji: "😷",
        name: "マスクの顔",
        description: "マスクをしている顔。病気予防を表現します。",
        usage: "風邪予防や体調管理の話題で使用します。",
      },
    ],
  },
  {
    category: "ネガティブ・怒り",
    emojis: [
      {
        emoji: "😠",
        name: "怒った顔",
        description: "眉をひそめて怒っている顔。怒りを表現します。",
        usage: "怒りや不満を伝える時に使用します。",
      },
      {
        emoji: "😡",
        name: "激怒の顔",
        description: "赤く怒った顔。強い怒りを表現します。",
        usage: "強い怒りや憤慨を伝える時に使用します。",
      },
      {
        emoji: "😤",
        name: "鼻から煙顔",
        description: "鼻から煙を出している顔。憤慨を表現します。",
        usage: "イライラや憤慨を伝える時に使用します。",
      },
    ],
  },
  {
    category: "驚き・ショック",
    emojis: [
      {
        emoji: "😱",
        name: "恐怖の叫び顔",
        description: "両手を上げて叫んでいる顔。強いショックを表現します。",
        usage: "大きな驚きやショックを受けた時に使用します。",
      },
      {
        emoji: "😨",
        name: "驚いた顔",
        description: "目を見開いて驚いている顔。驚きを表現します。",
        usage: "予想外の出来事があった時に使用します。",
      },
      {
        emoji: "😰",
        name: "不安な冷や汗顔",
        description: "冷や汗をかきながら不安そうな顔。心配を表現します。",
        usage: "不安や心配がある時に使用します。",
      },
    ],
  },
];

export default function Meanings() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const handleCopy = async (emoji: string) => {
    try {
      await navigator.clipboard.writeText(emoji);
      setSnackbar({
        open: true,
        message: "絵文字をコピーしました！",
        severity: "success",
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: "絵文字のコピーに失敗しました。",
        severity: "error",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>絵文字 意味辞典 - 表情や感情の解説 | 絵文字ミックス</title>
        <meta
          name="description"
          content="顔文字・表情、感情表現、場面別など、カテゴリー別に絵文字 意味や使い方をわかりやすく紹介。絵文字 意味を理解して、コミュニケーションをより豊かに。絵文字 意味の一覧で適切な使用シーンがすぐわかります。"
        />
        <meta
          name="keywords"
          content="絵文字 意味,顔文字,表情,感情表現,解説,使い方"
        />
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2rem", md: "2.75rem" },
            mb: { xs: 2, md: 3 },
            textAlign: "center",
            fontWeight: "bold",
            background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          絵文字 意味辞典
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "center",
            mb: { xs: 4, md: 6 },
            color: "text.secondary",
          }}
        >
          カテゴリー別に表情や感情を詳しく解説。
          シーンに合わせた使い方がすぐわかります。
        </Typography>

        {EMOJI_LIST.map((category) => (
          <Box key={category.category} sx={{ mb: 6 }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "1.5rem", md: "2rem" },
                mb: 3,
                fontWeight: "bold",
                color: "text.primary",
              }}
            >
              {category.category}
            </Typography>

            <Grid container spacing={3}>
              {category.emojis.map((item, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <Paper
                    elevation={1}
                    sx={{
                      p: 3,
                      height: "100%",
                      "&:hover": {
                        boxShadow: 3,
                        transform: "translateY(-2px)",
                        transition: "all 0.3s ease",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h3"
                          sx={{ fontSize: "2.5rem", mr: 2 }}
                        >
                          {item.emoji}
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() => handleCopy(item.emoji)}
                        title="絵文字をコピー"
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <ContentCopy />
                      </IconButton>
                    </Box>

                    <Typography
                      sx={{ mb: 2, color: "text.secondary", lineHeight: 1.6 }}
                    >
                      {item.description}
                    </Typography>

                    <Typography
                      sx={{
                        color: "text.secondary",
                        lineHeight: 1.6,
                        fontStyle: "italic",
                      }}
                    >
                      使用シーン：{item.usage}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            severity={snackbar.severity}
            sx={{ width: "100%" }}
            onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}
