import { Box, Container, Grid, Typography } from "@mui/material";

export default function Examples() {
  const examples = [
    {
      combo: ["🌸", "☔️"],
      description: "桜の花が雨のように降り注ぐ、ロマンチックな組み合わせ。",
    },
    {
      combo: ["🍓", "🎂"],
      description: "いちごたっぷりのバースデーケーキが作れます。",
    },
    {
      combo: ["🐰", "🐰"],
      description: "かわいい子うさぎがたくさん生まれる不思議な組み合わせ。",
    },
    {
      combo: ["🐕", "⛄️"],
      description: "シベリアンハスキーが誕生する意外な組み合わせ。",
    },
  ];

  return (
    <Box 
      component="section" 
      sx={{ 
        py: { xs: 4, md: 8 },
        backgroundColor: 'background.default',
      }}
    >
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          sx={{ 
            fontSize: { xs: '1.75rem', md: '2.25rem' },
            mb: { xs: 3, md: 6 },
            textAlign: "center",
            fontWeight: 'bold',
            background: 'linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)',
            backgroundClip: 'text',
            color: 'transparent',
          }}
        >
          面白い組み合わせ例
        </Typography>

        <Grid container spacing={{ xs: 2, md: 4 }}>
          {examples.map((example, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box 
                sx={{ 
                  p: { xs: 3, md: 4 },
                  borderRadius: 4,
                  backgroundColor: 'background.paper',
                  boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
                  transition: 'all 0.3s ease-in-out',
                  textAlign: 'center', // 添加文字居中对齐
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 12px 20px -5px rgba(0,0,0,0.15)',
                  },
                }}
              >
                <Box 
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    mb: 2
                  }}
                >
                  <Typography 
                    sx={{ 
                      fontSize: { xs: '1.5rem', md: '1.75rem' },
                      fontFamily: 'Noto Emoji, Apple Color Emoji, sans-serif',
                    }}
                  >
                    {example.combo[0]}
                  </Typography>
                  <Typography 
                    sx={{ 
                      fontSize: { xs: '1.25rem', md: '1.5rem' },
                      color: 'text.secondary',
                    }}
                  >
                    +
                  </Typography>
                  <Typography 
                    sx={{ 
                      fontSize: { xs: '1.5rem', md: '1.75rem' },
                      fontFamily: 'Noto Emoji, Apple Color Emoji, sans-serif',
                    }}
                  >
                    {example.combo[1]}
                  </Typography>
                </Box>
                <Typography 
                  variant="body1"
                  sx={{
                    color: 'text.secondary',
                    lineHeight: 1.7,
                    fontSize: { xs: '0.875rem', md: '1rem' },
                  }}
                >
                  {example.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
