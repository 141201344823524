import { Box, Typography } from '@mui/material';

interface FeatureCardProps {
  icon: string;
  title: string;
  description: string;
}

export function FeatureCard({ icon, title, description }: FeatureCardProps) {
  return (
    <Box 
      sx={{ 
        p: { xs: 2, md: 4 },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: 4,
        backgroundColor: 'background.paper',
        transition: 'all 0.3s ease-in-out',
        boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06)',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 20px 25px -5px rgba(0,0,0,0.1), 0 10px 10px -5px rgba(0,0,0,0.04)',
          '& .feature-icon': {
            transform: 'scale(1.1)',
          },
        },
      }}
    >
      <Typography 
        className="feature-icon"
        sx={{ 
          fontSize: { xs: '3rem', md: '4rem' },
          mb: { xs: 2, md: 3 },
          fontFamily: 'Noto Emoji, Apple Color Emoji, sans-serif',
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        {icon}
      </Typography>
      <Typography 
        variant="h3" 
        sx={{ 
          fontSize: { xs: '1.25rem', md: '1.5rem' },
          mb: 2,
          fontWeight: 700,
          color: 'text.primary',
        }}
      >
        {title}
      </Typography>
      <Typography 
        variant="body1" 
        sx={{ 
          color: 'text.secondary',
          lineHeight: 1.7,
          fontSize: { xs: '0.875rem', md: '1rem' },
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}
