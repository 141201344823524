import { Box, Typography } from "@mui/material";
import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <Box 
        component="footer" 
        sx={{ 
          py: 4,
          textAlign: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'text.secondary',
            fontSize: { xs: '0.75rem', sm: '0.875rem' }
          }}
        >
          &copy; {new Date().getFullYear()} emojimix.fun 絵文字ミックス. All rights reserved.
        </Typography>
      </Box>
    );
  }
}
