import { Dispatch, useEffect, useState, useRef } from "react";
import {
  getEmojiData,
  getNotoEmojiUrl,
  getSupportedEmoji,
  getFallbackEmojiUrl,
  getNextFallbackUrl,
  failedEmojiCache,
  updateFailedCache,
} from "./utils";
import { ImageListItem } from "@mui/material";
import { EmojiData } from "./types";

export default function RightEmojiList({
  rightSearchResults,
  selectedLeftEmoji,
  selectedRightEmoji,
  handleRightEmojiClicked,
}: {
  rightSearchResults: Array<string>;
  selectedLeftEmoji: string;
  selectedRightEmoji: string;
  handleRightEmojiClicked: Dispatch<string>;
}) {
  const [emojiList, setEmojiList] = useState<JSX.Element[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const hasSelectedLeftEmoji = selectedLeftEmoji !== "";
  const loadedEmojisRef = useRef<Map<string, EmojiData>>(new Map());

  useEffect(() => {
    async function loadEmojiList() {
      try {
        let knownSupportedEmoji = await getSupportedEmoji();

        if (rightSearchResults.length > 0) {
          knownSupportedEmoji = knownSupportedEmoji.filter((emoji) =>
            rightSearchResults.includes(emoji)
          );
        }

        let possibleEmoji: string[] = [];
        if (hasSelectedLeftEmoji) {
          const data = await getEmojiData(selectedLeftEmoji);
          possibleEmoji = Object.keys(data.combinations);
        }

        const emojiElements = await Promise.all(
          knownSupportedEmoji.map(async (emojiCodepoint) => {
            let data = loadedEmojisRef.current.get(emojiCodepoint);
            if (!data) {
              try {
                data = await getEmojiData(emojiCodepoint);
                loadedEmojisRef.current.set(emojiCodepoint, data);
              } catch (err) {
                console.error(`Failed to load emoji data: ${emojiCodepoint}`);
                return null;
              }
            }

            if (!data) return null;

            let isValidCombo = true;
            if (hasSelectedLeftEmoji) {
              isValidCombo = possibleEmoji.includes(emojiCodepoint);
            }

            return (
              <div key={data.alt}>
                <ImageListItem
                  onClick={(event) =>
                    hasSelectedLeftEmoji && isValidCombo
                      ? handleRightEmojiClicked(emojiCodepoint)
                      : null
                  }
                  sx={{
                    p: 0.5,
                    borderRadius: 2,
                    opacity: (theme) => {
                      if (!hasSelectedLeftEmoji) {
                        return 0.1;
                      }
                      return isValidCombo ? 1 : 0.1;
                    },
                    backgroundColor: (theme) =>
                      selectedRightEmoji === emojiCodepoint
                        ? theme.palette.action.selected
                        : theme.palette.background.default,
                    "&:hover": {
                      backgroundColor: (theme) => {
                        if (hasSelectedLeftEmoji && isValidCombo) {
                          return theme.palette.action.hover;
                        }
                        return theme.palette.background.default;
                      },
                    },
                  }}
                >
                  <img
                    loading="lazy"
                    width="32px"
                    height="32px"
                    alt={data.alt}
                    src={getNotoEmojiUrl(data.emojiCodepoint)}
                    onError={(e) => {
                      const img = e.target as HTMLImageElement;
                      if (!failedEmojiCache.has(data.emojiCodepoint)) {
                        updateFailedCache(data.emojiCodepoint);
                      }
                      img.src =
                        "https://fonts.gstatic.com/s/e/notoemoji/latest/2753/512.png";
                      img.onerror = null;
                    }}
                    style={{
                      width: "32px",
                      height: "32px",
                      objectFit: "contain",
                    }}
                  />
                </ImageListItem>
              </div>
            );
          })
        );

        setEmojiList(
          emojiElements.filter(
            (element): element is JSX.Element => element !== null
          )
        );
      } catch (err) {
        console.error("Error loading emoji list:", err);
        setError(
          err instanceof Error ? err.message : "Failed to load emoji list"
        );
      } finally {
        setIsLoading(false);
      }
    }

    loadEmojiList();
  }, [
    rightSearchResults,
    selectedLeftEmoji,
    selectedRightEmoji,
    handleRightEmojiClicked,
    hasSelectedLeftEmoji,
  ]);

  if (error) {
    return <div>エラーが発生しました: {error}</div>;
  }

  if (isLoading) {
    return <div>読み込み中...</div>;
  }

  return <>{emojiList}</>;
}
