import { AppBar, Toolbar, Button, Box, Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AppBar
      position="sticky"
      sx={{
        bgcolor: "background.paper",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex", gap: 2 }}>
            <Button
              color={location.pathname === "/" ? "primary" : "inherit"}
              onClick={() => navigate("/")}
              sx={{
                background:
                  location.pathname === "/"
                    ? "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)"
                    : "transparent",
                color: location.pathname === "/" ? "white" : "text.primary",
                "&:hover": {
                  background:
                    location.pathname === "/"
                      ? "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)"
                      : "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              絵文字ミックス
            </Button>
            <Button
              color={location.pathname === "/games" ? "primary" : "inherit"}
              onClick={() => navigate("/games")}
              sx={{
                background:
                  location.pathname === "/games"
                    ? "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)"
                    : "transparent",
                color:
                  location.pathname === "/games" ? "white" : "text.primary",
                "&:hover": {
                  background:
                    location.pathname === "/games"
                      ? "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)"
                      : "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              ゲーム
            </Button>
            <Button
              color={location.pathname === "/meanings" ? "primary" : "inherit"}
              onClick={() => navigate("/meanings")}
              sx={{
                background:
                  location.pathname === "/meanings"
                    ? "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)"
                    : "transparent",
                color:
                  location.pathname === "/meanings" ? "white" : "text.primary",
                "&:hover": {
                  background:
                    location.pathname === "/meanings"
                      ? "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)"
                      : "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              絵文字 意味一覧
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
