import { Box, Container, Grid, Typography } from "@mui/material";
import { FeatureCard } from "./FeatureCard";

export default function Features() {
  return (
    <Box
      component="section"
      sx={{
        py: { xs: 4, md: 8 },
        bgcolor: "background.paper",
        borderTop: "1px solid",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "1.75rem", md: "2.25rem" },
            mb: { xs: 3, md: 6 },
            textAlign: "center",
            fontWeight: "bold",
            background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          絵文字ミックスの特徴
        </Typography>

        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          justifyContent="center"
          sx={{ mb: { xs: 6, md: 8 } }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <FeatureCard
              icon="🎨"
              title="10万以上の組み合わせ"
              description="ユニークな絵文字の組み合わせを作成できます。無限の可能性が広がります。"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FeatureCard
              icon="💾"
              title="簡単保存"
              description="作成した絵文字は画像として保存可能。SNSやメッセージで共有できます。"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FeatureCard
              icon="🎲"
              title="ランダム機能"
              description="インスピレーションが必要な時は、ランダム機能で意外な組み合わせを発見できます。"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
