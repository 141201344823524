import { EmojiData, EmojiMetadata } from "./types";

// 缓存机制
let metadataCache: EmojiMetadata | null = null;

const BASE_URL = "https://emoji-data.studenthc.workers.dev";
const METADATA_URL = `${BASE_URL}/metadata.json`;

// 修改缓存实现，使用 localStorage 来持久化失败记录
const FAILED_EMOJI_CACHE_KEY = "failed-emoji-cache";

// 初始化失败缓存并导出
export let failedEmojiCache: Set<string>;
try {
  const cached = localStorage.getItem(FAILED_EMOJI_CACHE_KEY);
  failedEmojiCache = new Set(cached ? JSON.parse(cached) : []);
} catch (e) {
  failedEmojiCache = new Set();
}

// 导出更新缓存的函数
export function updateFailedCache(emojiCodepoint: string) {
  if (failedEmojiCache.has(emojiCodepoint)) {
    return; // 如果已经在缓存中，直接返回
  }

  failedEmojiCache.add(emojiCodepoint);
  try {
    localStorage.setItem(
      FAILED_EMOJI_CACHE_KEY,
      JSON.stringify(Array.from(failedEmojiCache))
    );
  } catch (e) {
    console.warn("Failed to save failed emoji cache:", e);
  }
}

/**
 * 获取 metadata
 */
async function getMetadata(): Promise<EmojiMetadata> {
  if (metadataCache) {
    return metadataCache;
  }

  try {
    // 从 localStorage 获取缓存
    const cachedMetadata = localStorage.getItem("emoji-metadata");
    if (cachedMetadata) {
      try {
        const data = JSON.parse(cachedMetadata);
        metadataCache = data;
        return data;
      } catch (e) {
        // 如果解析失败，清除可能损坏的缓存
        localStorage.removeItem("emoji-metadata");
      }
    }

    const response = await fetch(METADATA_URL);
    if (!response.ok) {
      throw new Error(`Failed to fetch metadata: ${response.statusText}`);
    }
    const data = await response.json();
    metadataCache = data;

    // 尝试保存到 localStorage，如果失败也不影响功能
    try {
      localStorage.setItem("emoji-metadata", JSON.stringify(data));
    } catch (e) {
      // 如果存储失败（比如配额超出），只在控制台记录，不抛出错误
      console.warn("Failed to cache metadata in localStorage:", e);
      // 清理 localStorage 以释放空间
      localStorage.clear();
    }

    return data;
  } catch (error) {
    console.error("Failed to fetch metadata:", error);
    throw error;
  }
}

/**
 * 获取支持的 emoji 列表
 */
export async function getSupportedEmoji(): Promise<string[]> {
  try {
    const metadata = await getMetadata();
    return metadata.knownSupportedEmoji || [];
  } catch (error) {
    console.error("Error fetching supported emoji:", error);
    return [];
  }
}

/**
 * 获取 emoji 数据
 */
export async function getEmojiData(emojiCodepoint: string): Promise<EmojiData> {
  try {
    const metadata = await getMetadata();
    const emojiData = metadata.data[emojiCodepoint];
    if (!emojiData) {
      throw new Error(`No data found for emoji: ${emojiCodepoint}`);
    }
    return emojiData;
  } catch (error) {
    console.error(`Error fetching emoji data for ${emojiCodepoint}:`, error);
    throw error;
  }
}

/**
 * 格式化表情代码点
 */
function formatEmojiCodepoint(
  emojiCodepoint: string,
  format: "noto" | "github" | "simplified" = "noto"
): string {
  const parts = emojiCodepoint.split("-");

  switch (format) {
    case "noto":
      // Noto CDN 格式：调整代码点顺序，确保 200d 在正确位置
      return parts
        .sort((a, b) => {
          // 确保 200d 在相关代码点之间
          if (a === "200d") return 1;
          if (b === "200d") return -1;
          // fe0f 应该跟随其修饰的代码点
          if (a === "fe0f") return 1;
          if (b === "fe0f") return -1;
          return 0;
        })
        .join("-");

    case "github":
      // GitHub 格式：所有部分用下划线连接，保持原始顺序
      return parts.map((part) => part.toLowerCase().padStart(4, "0")).join("_");

    case "simplified":
      // 简化格式：只保留基本代码点
      return parts.filter((part) => !["fe0f", "200d"].includes(part)).join("-");

    default:
      return emojiCodepoint;
  }
}

/**
 * 获取所有可能的表情 URL
 */
function getAllPossibleUrls(emojiCodepoint: string): string[] {
  const urls = new Set<string>();

  // 1. 原始格式
  urls.add(
    `https://fonts.gstatic.com/s/e/notoemoji/latest/${formatEmojiCodepoint(
      emojiCodepoint,
      "noto"
    )}/512.png`
  );

  // 2. GitHub 格式
  urls.add(
    `https://raw.githubusercontent.com/googlefonts/noto-emoji/main/png/72/emoji_u${formatEmojiCodepoint(
      emojiCodepoint,
      "github"
    )}.png`
  );

  // 3. 简化格式
  urls.add(
    `https://fonts.gstatic.com/s/e/notoemoji/latest/${formatEmojiCodepoint(
      emojiCodepoint,
      "simplified"
    )}/512.png`
  );

  // 4. 特殊处理某些已知的问题表情
  const specialCases: { [key: string]: string[] } = {
    "1f636-200d-1f32b-fe0f": [
      "https://fonts.gstatic.com/s/e/notoemoji/latest/1f636_200d_1f32b/512.png",
      "https://fonts.gstatic.com/s/e/notoemoji/latest/1f636_1f32b/512.png",
    ],
    "2764-fe0f-200d-1fa79": [
      "https://fonts.gstatic.com/s/e/notoemoji/latest/2764_200d_1fa79/512.png",
      "https://fonts.gstatic.com/s/e/notoemoji/latest/2764_1fa79/512.png",
    ],
  };

  if (specialCases[emojiCodepoint]) {
    specialCases[emojiCodepoint].forEach((url) => urls.add(url));
  }

  return Array.from(urls);
}

/**
 * 获取 Noto Emoji URL
 */
export function getNotoEmojiUrl(emojiCodepoint: string): string {
  try {
    // 特殊格式表情映射
    const specialFormats: Record<string, string> = {
      "1f636-200d-1f32b-fe0f": "1f636_200d_1f32b",
      "2764-fe0f-200d-1fa79": "2764_200d_1fa79",
      "1f471-200d-2640-fe0f": "1f471_200d_2640",
      "1f64d-200d-2642-fe0f": "1f64d_200d_2642",
      "1f9d4-200d-2642-fe0f": "1f9d4_200d_2642",
    };

    // 检查是否是特殊格式表情
    if (specialFormats[emojiCodepoint]) {
      return `https://fonts.gstatic.com/s/e/notoemoji/latest/${specialFormats[emojiCodepoint]}/512.png`;
    }

    // 标准格式处理 - 简化处理逻辑
    const formattedCodepoint = emojiCodepoint.split("-").join("_");

    return `https://fonts.gstatic.com/s/e/notoemoji/latest/${formattedCodepoint}/512.png`;
  } catch (error) {
    console.error("Error formatting emoji URL:", error, emojiCodepoint);
    // 返回一个通用的表情URL而不是更新失败缓存
    return "https://fonts.gstatic.com/s/e/notoemoji/latest/2753/512.png";
  }
}

// 移除不需要的函数
export function getFallbackEmojiUrl(emojiCodepoint: string): string {
  return "https://fonts.gstatic.com/s/e/notoemoji/latest/2753/512.png";
}

export function getNextFallbackUrl(emojiCodepoint: string): string | null {
  return null;
}
// 添加一个用于检查表情是否已失败的函数
export function isEmojiFailedToLoad(emojiCodepoint: string): boolean {
  return false; // 始终返回 false，不再使用缓存
}
