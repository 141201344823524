import { Box, Container, Typography, Link } from "@mui/material";

export default function FAQ() {
  const faqs = [
    {
      question: "絵文字ミックスとは何ですか？",
      answer:
        "絵文字ミックスは、Google の絵文字キッチンを活用して2つの絵文字を組み合わせ、ユニークな新しい絵文字を作成できる無料ツールです。",
    },
    {
      question: "作成した絵文字はどのように保存できますか？",
      answer:
        "作成した絵文字は簡単にダウンロードできます。また、複数の組み合わせを一括でダウンロードすることも可能です。",
    },
    {
      question: "無料で利用できますか？",
      answer: "はい、完全無料でご利用いただけます。登録も不要です。",
    },
    {
      question: "どのブラウザに対応していますか？",
      answer:
        "主要なブラウザ（Chrome、Firefox、Safari、Edge）に対応しています。",
    },
    {
      question: "絵文字ミックスの組み合わせ数はいくつありますか？",
      answer:
        "10万以上の組み合わせが可能です。毎日新しい組み合わせが追加され、さらに増え続けています。",
    },
    {
      question: "作成した絵文字ミックスはどこで使えますか？",
      answer:
        "LINE、Twitter、Instagram、Slackなど、画像を送信できるすべてのアプリで使用できます。",
    },
    {
      question: "他の絵文字ゲームはありますか？",
      answer: "はい、以下のような人気ゲームもおすすめです：",
      links: [
        {
          text: "Google Emoji Kitchen",
          url: "https://www.google.com/search?q=emoji+kitchen",
          rel: "noopener noreferrer",
        },
      ],
    },
  ];

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 6, md: 8 },
        bgcolor: "background.paper",
        borderTop: "1px solid",
        borderColor: "divider",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "1.75rem", md: "2.25rem" },
            mb: { xs: 4, md: 6 },
            textAlign: "center",
            fontWeight: "bold",
            background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          よくある質問
        </Typography>

        <Box
          sx={{
            display: "grid",
            gap: { xs: 3, md: 4 },
            maxWidth: "800px",
            mx: "auto",
          }}
        >
          {faqs.map((faq, index) => (
            <Box
              key={index}
              sx={{
                p: { xs: 3, md: 4 },
                borderRadius: 4,
                backgroundColor: "background.default",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 12px 20px -5px rgba(0,0,0,0.1)",
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: "1.125rem", md: "1.25rem" },
                  mb: 2,
                  fontWeight: 600,
                  color: "text.primary",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Box
                  component="span"
                  sx={{
                    color: "primary.main",
                    fontSize: "0.875rem",
                    fontWeight: 700,
                  }}
                >
                  Q.
                </Box>
                {faq.question}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "text.secondary",
                  lineHeight: 1.7,
                  fontSize: { xs: "0.875rem", md: "1rem" },
                  pl: "1.5rem",
                }}
              >
                {faq.answer}
                {faq.links && (
                  <Box sx={{ mt: 2 }}>
                    {faq.links.map((link, linkIndex) => (
                      <Link
                        key={linkIndex}
                        href={link.url}
                        target="_blank"
                        rel={link.rel}
                        sx={{
                          display: "block",
                          color: "primary.main",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {link.text}
                      </Link>
                    ))}
                  </Box>
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
