import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import {
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Popover,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  BackspaceOutlined,
  Close,
  ManageSearchOutlined,
  Search as SearchIcon,
} from "@mui/icons-material";
import { getEmojiData, getNotoEmojiUrl } from "./utils";

export default function Search({
  setSearchResults,
  setMobileSearchIsOpen,
  handleRandomize,
  selectedEmoji,
  uuid,
  isRightSearch,
  disabled,
}: {
  setSearchResults: Dispatch<SetStateAction<Array<string>>>;
  setMobileSearchIsOpen: Dispatch<SetStateAction<boolean>>;
  handleRandomize: () => void;
  selectedEmoji: string;
  uuid: string;
  isRightSearch: boolean;
  disabled?: boolean;
}) {
  const [value, setValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(value, 300);
  const [
    mobileSearchPopoverAnchorElement,
    setMobileSearchPopoverAnchorElement,
  ] = React.useState<HTMLElement | null>(null);
  const mobileSearchOpen = Boolean(mobileSearchPopoverAnchorElement);

  const currentWidth = window.innerWidth;
  const collapseSearch = currentWidth < 600;
  const hasSearchValue = value !== "";

  const handleMobileSearchClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setMobileSearchIsOpen(true);
    setMobileSearchPopoverAnchorElement(event.currentTarget.parentElement!);
  };

  const handleMobileSearchClose = () => {
    setMobileSearchIsOpen(false);
    setMobileSearchPopoverAnchorElement(null);
  };

  /**
   * Hacky input to clear text box when randomizing from parent element
   */
  useEffect(() => {
    setValue("");
  }, [uuid]);

  /**
   * Debounce and sanitize search queries
   */
  useEffect(() => {
    async function search() {
      let results = [];
      setIsSearching(true);
      if (debouncedSearchTerm) {
        var requestQuery = debouncedSearchTerm.trim().toLowerCase();
        requestQuery =
          requestQuery.length > 128
            ? requestQuery.substring(0, 127)
            : requestQuery;
        const data = await fetch(
          `https://backend.emojikitchen.dev/?q=${requestQuery}`
        );
        results = await data.json();
      }

      setIsSearching(false);
      setSearchResults(results);
    }

    if (debouncedSearchTerm.trim().length >= 3) {
      search();
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <Box>
      {collapseSearch ? (
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{ p: "10px" }}
            onClick={handleMobileSearchClick}
            disabled={disabled}
          >
            <ManageSearchOutlined />
          </IconButton>
        </Paper>
      ) : (
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <IconButton sx={{ p: "10px" }} disabled={disabled}>
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="絵文字を検索"
            value={value}
            disabled={disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(event.target.value);
            }}
          />
          {hasSearchValue ? (
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              onClick={() => setValue("")}
            >
              <Close />
            </IconButton>
          ) : null}
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            onClick={handleRandomize}
            disabled={disabled}
            sx={{
              p: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Noto Emoji, Apple Color Emoji, sans-serif",
                fontSize: "20px",
                lineHeight: 1,
                height: "24px",
                width: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              🎲
            </Typography>
          </IconButton>
        </Paper>
      )}

      <Popover
        open={mobileSearchOpen}
        anchorEl={mobileSearchPopoverAnchorElement}
        onClose={handleMobileSearchClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <TextField
          autoFocus
          placeholder="絵文字を検索"
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
          }}
          inputRef={(input) => {
            setTimeout(() => {
              if (input) {
                input.focus();
              }
            }, 75);
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                size="small"
                disabled={value === ""}
                color="primary"
                sx={{ p: "10px" }}
                onClick={() => setValue("")}
              >
                <BackspaceOutlined />
              </IconButton>
            ),
          }}
        />
      </Popover>
    </Box>
  );
}
