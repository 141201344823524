import { Box, Container, Typography } from "@mui/material";

export default function Header() {
  return (
    <Box
      component="header"
      sx={{
        py: { xs: 6, md: 8 },
        textAlign: "center",
        backgroundColor: "background.paper",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2rem", md: "2.75rem" },
            mb: { xs: 2, md: 3 },
            fontWeight: "bold",
            background: "linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          絵文字ミックス
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "1.25rem", md: "1.5rem" },
            mb: { xs: 3, md: 4 },
            color: "text.secondary",
            fontWeight: 500,
          }}
        >
          10万以上の絵文字の組み合わせを簡単に作成・検索
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mb: 2,
            fontSize: { xs: "1rem", md: "1.125rem" },
            maxWidth: "600px",
            mx: "auto",
            color: "text.secondary",
            lineHeight: 1.7,
          }}
        >
          ユニークな絵文字の組み合わせを簡単に作成・検索・ダウンロードできます。
          完全無料でご利用いただけます。
        </Typography>
      </Container>
    </Box>
  );
}
